import { Injectable, PLATFORM_ID, inject } from "@angular/core";
import { Router } from "@angular/router";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { login, loginFailure, loginLoading, loginOAuth, loginSuccess, logout, oauthLoading, setIsFirstLogin } from "../actions/authentication.action";
import { catchError, finalize, map, mergeMap, of, tap } from "rxjs";

import { AuthService } from "app/core/auth/service/auth.service";
import { CookiesUtilsService } from "app/services/cookies-utils/cookies-utils.service";
import { AuthenticationResponse } from "app/core/models/authentication-response";
import {jwtDecode} from  'jwt-decode';
import { isPlatformBrowser } from "@angular/common";


@Injectable()
export class AuthenticationEffect {
  private actions$: Actions = inject(Actions)
  private _store: Store = inject(Store)
  private _authService: AuthService = inject(AuthService)
  private _cookiesUtilsService: CookiesUtilsService = inject(CookiesUtilsService)
  private _platformDialogId  = inject(PLATFORM_ID)
  private _router = inject(Router);

    login$ = createEffect(() =>
        this.actions$.pipe(
            ofType(login),
            mergeMap(
                ({ username, password, rememberMe }) => this._authService.signIn({
                    credentials: {
                        username, password, rememberMe
                    }
                }).pipe(
                    tap((response:AuthenticationResponse) => {
                        this._cookiesUtilsService.setAccessToken({token:response.id_token});
                    }),
                    map((response) => {
                      const tokenDecoded= jwtDecode(response.id_token) as any
                      this._store.dispatch(setIsFirstLogin({isFirstLogin:typeof tokenDecoded?.firstLogin === 'boolean' ? tokenDecoded.firstLogin  :true}))
                      return loginSuccess({ accessToken: response.id_token, })
                    }
                    ),
                    catchError((error) =>
                        of(loginFailure({ loginError: error }))
                    ),
                    finalize(() =>
                        this._store.dispatch(loginLoading({ isLoading: false }))
                    )
                )
            )
        )
    );


  loginOAuth$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loginOAuth),
      mergeMap(
        ({ ssoToken, source }) => this._authService.sso({ source, ssoToken }).pipe(
          tap((response) => {
            this._cookiesUtilsService.setAccessToken({ token: response.id_token });
          }),
          map((response) => {
            return loginSuccess({ accessToken: response.id_token });
          }),
          catchError((error) => {
            console.log('catch error ', error)
            return of(loginFailure({ loginError: error }))
          }),
          finalize(() => {
            this._store.dispatch(oauthLoading({ isOAuthLoading: false, oAuthSource: source }));
          })
        )
      )
    )
  );

    logout$ = createEffect(() =>
        this.actions$.pipe(
            ofType(logout),
            tap(({redirect}) => {
                this._cookiesUtilsService.removeAccessToken();
                if(redirect){
                    if(isPlatformBrowser(this._platformDialogId)){
                        window.location.replace('');
                    } else{
                      this._router.navigate([''])
                    }
                }
            }),
            catchError((error) => of(error)),
        ),
        { dispatch: false }
    )
}
