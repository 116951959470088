import { Routes } from '@angular/router';
import { Constants } from './core/data/constants';
import { noAuthGuard } from './core/auth/no-auth.guard';
import { LayoutComponent } from './layout/layout.component';
import { isFirstLoginGuard } from './core/auth/is-first-login.guard';
import { authGuard } from './core/auth/auth.guard';

export const routes: Routes = [     
   
      { 
        path: '', 
        pathMatch: 'full', 
        redirectTo: Constants.HOME_ROUTE, 
      },

      //  route with layout 
      {
        path: '',
        component: LayoutComponent,
        data: 
          {
            layout: 'app-layout'
          },
        children: [
          
            { 
              path : Constants.ANNOUNCEMENTS_ROUTE,
              loadChildren: () => import('app/modules/announcements/announcements.routes')
            },
            // { to be removed in the future 
            //   path: Constants.QUICK_APPLY,
            //   loadChildren: () => import('app/shared/components/quick-apply/quick-apply.routes')
            // },
            { 
              path : Constants.CANDIDACIES_ROUTE,
              canActivate: [authGuard],
              canActivateChild: [authGuard], 
              loadChildren :()=> import('app/modules/candidacies/candidacies.routes')
            },
            { 
              path : Constants.ALERTS_ROUTE,
              canActivate: [authGuard],
              canActivateChild: [authGuard], 
              loadChildren :()=> import('app/modules/alerts/alerts.routes').then((m) => m.default)
            },
            { 
              path: Constants.TRAINING_ROUTE, 
              loadChildren: () => import('app/modules/training/training.routes')
            },
        ]
      },
      // route with empty layout
      {
        path: '',
        component: LayoutComponent,
        data: {
            layout: 'empty-layout'
        },
        children: [

            { 
              path : Constants.ABOUT_US_ROUTE , 
              loadChildren: ()=> import('app/modules/about-us/about-us.routes')
            },
            { 
              path: Constants.HOME_ROUTE, 
              pathMatch: 'full',
              loadChildren: () => import('app/modules/home-page/home-page.routes')
            },
            { 
              path:  Constants.SEARCH_ROUTE, 
              loadChildren: () => import('app/modules/search-page/search-page.routes'), 
            },
            { 
              path: Constants.CONTACT_US_ROUTE, 
              loadChildren: () => import('app/modules/contact-us/contact-us.routes')
            },
            { 
              path: Constants.CREATE_JOB_OFFER_ROUTE, 
              loadChildren: () => import('app/modules/create-job-offer/create-job-offer.routes')
            },
            { 
              path: Constants.FORGOT_ROUTE,
              canActivate: [noAuthGuard],
              canActivateChild: [noAuthGuard],
              loadChildren: () => import('app/modules/auth/forgot-password/forgot-password.module').then((m) => m.AuthForgotPasswordModule) },
            { 
              path: Constants.LOGIN_ROUTE, 
              canActivate: [noAuthGuard],
              canActivateChild: [noAuthGuard],
              data: { layout: 'empty-layout' },
              loadChildren: () => import('app/modules/auth/sign-in/sign-in.routes'), 
            },
            { 
              path:  Constants.ACCOUNT_ACTIVATION_ROUTE, 
              canActivate: [noAuthGuard],
              canActivateChild: [noAuthGuard],
              loadChildren: () => import('app/modules/auth/account-activation/account-activation.routes'), 
            },
            { 
              path:Constants.REGISTER_ROUTE,
              canActivate: [noAuthGuard],
              canActivateChild: [noAuthGuard],
              loadChildren: ()=>import('app/modules/auth/signup/signup.routes')
            },
            {
              path: Constants.ACCOUNT_FINALIZATION_ROUTE,
              canActivate : [isFirstLoginGuard],
              canActivateChild : [isFirstLoginGuard],
              loadChildren:()=> import('app/modules/account-finalization/account-finalization.routes'),
            },
            { 
              path:Constants.RESET_PASSWORD_ROUTE,
              canActivate: [noAuthGuard],
              canActivateChild: [noAuthGuard],
              loadChildren: ()=>import('app/modules/auth/reset-password/reset-password.routes')
            },
            { 
              path:Constants.LOGOUT_ROUTE,
              canActivate: [authGuard],
              canActivateChild: [authGuard],
              loadChildren: ()=>import('app/modules/auth/sign-out/sign-out.routes')
            },
            { 
              path : Constants.PROFILE_ROUTE, 
              canActivate: [authGuard],
              canActivateChild: [authGuard],
              loadChildren :()=> import('app/modules/profile/profile.routes')
            },
            {
              path: Constants.SOCIAL_REDIRECTION_ROUTE,
              canActivate: [noAuthGuard],
              canActivateChild: [noAuthGuard],
              loadChildren: () => import('./modules/auth/social-redirection/social-redirection.routes'),
            },
          
          ]
      },
      
      // no match route
      {
        path: '**',
        redirectTo: Constants.NOT_FOUND_ROUTE,
      },
      {
        path: Constants.NOT_FOUND_ROUTE,
        loadChildren: ()=> import('app/modules/errors/page-not-found/page-not-found.routes')
      },
];
